import Instafeed from "instafeed.js";


$('.js-instafeed').each(function() {
    // doc - http://instafeedjs.com
    // (outdated due to Instagram change of policy - access token is required)
    var feed = new Instafeed({
        get: 'user',    // cannot do popular anymore
        target: this,
        userId: $(this).data('user_id'),
        clientId: $(this).data('client_id'),
        accessToken: $(this).data('access_token'),
        template: '<a href="{{link}}" alt="{{caption}}" target="_blank"><img src="{{image}}" /></a>'
    });
    feed.run();
});
